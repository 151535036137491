import man from "../assets/man.jpeg";
import woman from "../assets/woman.jpeg";
import girl from "../assets/girl.jpeg";
import Sundae from "../assets/Sundae.svg";
import SpecialitySundae from "../assets/SpecialitySundae.svg";
import whiteSpecialitySundae from "../assets/whiteSpecuialitySundae.svg";
import BananaSplit from "../assets/BananaSplit.svg";
import MilkShake from "../assets/milkShake.svg";
import sessionIcon from "../assets/sessionIcon.svg";
import SesionStar from "../assets/Star.svg";
import AiReport from "../assets/aiReport.svg";
import LockReport from "../assets/lock.svg";
import ProfileIcon from "../assets/Profileicon.svg";
import PlanIcon from "../assets/Planicon.svg";
import FeedBackIcon from "../assets/FeedBackicon.svg";
import AddIcon from "../assets/addicon.svg";
import ActivesessionIcon from "../assets/activesession.svg";
import ActiveStar from "../assets/GreenStar.svg";
import ActiveFeedBacIcon from "../assets/activeFeedBack.svg";
import ActiveProfileIcon from "../assets/activeProfileicon.svg";
import ActivePlanIcon from "../assets/activeplanicon.svg";
import ActiveAddIcon from "../assets/Add.svg";

export const STORAGE_KEY_ACCESS = "CAREER_SCOOPS_AUTH_ACCESS";
export const STORAGE_KEY_REFRESH = "CAREER_SCOOPS_AUTH_REFRESH";
export const STORAGE_KEY_USER = "CAREER_SCOOPS_USER";

export const FAQ = {
  careerexplorer: [
    {
      question: "What is a 'Career Scoop' Micro-Mentorship session?",
      answere:
        "A 'Career Scoop' Micro-Mentorship Session refers to a virtual session designed to connect you with a professional in your chosen career field. Varying by plan, this personalized session typically can be scheduled at any convenient time within 12 months, offering tailored advice and insights to support your career development journey.",
      isOpen: false,
      id: 1,
    },
    {
      question: "What is Career Scoops, and how can it help me?",
      answere:
        "Career Scoops is an innovative platform designed for young individuals exploring their career paths. Our goal is to help you find your path to career success. By harnessing AI technology, we provide you with personalized career recommendations based on your unique profile and offer actionable insights that help you focus on what matters most to succeed in your career journey. Additionally, we give you the option to gain real-world career insights by connecting with industry professionals. We aim to empower you with the clarity and focus you deserve, meeting today's world demands.",
      isOpen: false,
      id: 2,
    },
    {
      question: "How does my 1:1 Micro-Mentorship session go?",
      answere:
        "After you've booked your 1:1 Micro-Mentorship session through your profile, we will coordinate with a Community Partner (also known as a Mentor) to schedule your session. All sessions are conducted virtually, providing a comfortable setting for you to ask a wide range of questions about career choices, day-to-day activities, essential skillsets, and strategies for success. The session is designed to be informal and friendly, aiming to provide you with valuable real-world insights.",
      isOpen: false,
      id: 3,
    },
    {
      question: "What makes Career Scoops different?",
      answere:
        "Career Scoops stands out by blending AI-driven personalization with real-world career insights, offering a unique platform for young individuals. Unlike others, it not only matches you with suitable careers based on your profile but also provides actionable insights to prioritize your steps effectively and build your plan. Plus, its unique feature to opt in into a connection to industry professionals offers you invaluable real-world perspectives, ensuring you're ready for the career path you choose, all while focusing on what truly matters for your success in today's demanding job market.",
      isOpen: false,
      id: 4,
    },
    {
      question: "Who are Community Partners (aka Mentors)?",
      answere:
        "Community Partners, also known as Mentors, are seasoned career professionals passionate about helping young individuals. Whether you are interested in law, healthcare or real estate, we have a professional in our network who can talk to you and share their insights and experiences, helping you navigate your future professional journey.",
      isOpen: false,
      id: 5,
    },
    {
      question: "What should I expect from the AI Career Assessment?",
      answere:
        "Our relatable, scenario-based Career Assessment takes about 10-15 minutes to complete. It analyzes various aspects of your profile, delivering the top 10 personalized career recommendations that align with your skills, personality and more. Engineered to evaluate over 840 different career fields, the assessment identifies your most compatible potential careers. It can reveal unexpected and exciting career opportunities you may not have considered before.",
      isOpen: false,
      id: 6,
    },
    {
      question: "How do I get started on the platform?",
      answere:
        "To begin your journey on the Career Scoops platform, simply sign up as a 'Career Explorer' and complete your profile. If you're affiliated with an organization, remember to enter your affiliate code during registration. After signing up, you can dive into our interactive AI Career Assessment, which is the first step guiding you towards your career exploration journey.",
      isOpen: false,
      id: 7,
    },
    {
      question: "What is the Career Scoops Insights Report?",
      answere:
        "The Career Scoops Insights Report is a comprehensive document designed to offer personalized career guidance. It combines AI analysis with detailed evaluations of your interests, skills, and personality, presenting the top 10 career paths that align with your unique profile. This report, enriched with insights from analyzing over 840 career fields, aims to open your mind to various opportunities, encouraging exploration and informed decision-making. Additionally, it serves as a foundation for further discussions and planning, guiding you towards fulfilling and suitable career choices.",
      isOpen: false,
      id: 8,
    },
    {
      question: "How much does it cost?",
      answere:
        "Upon registering on the platform, you'll find a selection of plan options designed to meet diverse needs for personalized career exploration. Each plan is tailored to provide the right level of support and resources for your individual journey. Additionally, for those who wish to discuss costs with a parent or guardian, we offer a convenient 'text to parent' feature, making it easy to share information and involve them in the decision-making process.",
      isOpen: false,
      id: 9,
    },
  ],
  communitypartner: [
    {
      question: "What is the story of Career Scoops?",
      answere:
        "Our journey at Career Scoops began with Arman, a teenager faced with the daunting uncertainties of choosing a career path and the desire to focus on what matters most. Joined by his mother and a team of passionate supporters, they set out to create a unique solution tailored to today's needs, aimed at empowering young adults to realize their career potential. Now, as a TechEd Company based in Central Florida, Career Scoops is committed to assisting over 23 million young adults across the U.S. in their career discovery journey. By blending personal experiences with cutting-edge technology, we pave the way for fulfilling careers for a new generation.",
      isOpen: false,
      id: 1,
    },
    {
      question: "Why should I get involved with Career Scoops?",
      answere:
        "Joining Career Scoops as a mentor allows you to make a meaningful impact by sharing your knowledge and experiences to empower young individuals in their career journeys. Recognizing you are a busy career professional, we ensure our virtual micro-mentorship sessions format accommodate your schedule, requiring minimal commitment. This is a one-of-a-kind opportunity to give back and contribute to the success of the next generation, for busy professionals like you.",
      isOpen: false,
      id: 2,
    },
    {
      question: "What is your mission?",
      answere:
        "Our mission at Career Scoops is to empower young individuals to navigate their career paths with confidence and clarity responding to today’s world challenges. We aim to revolutionize career guidance, leveraging advanced AI technology for actionable recommendations and exposure to real-world advice through a network of community partners. By providing insightful, data-driven recommendations and connecting users with industry professionals, we strive to inspire informed decision-making and facilitate meaningful career exploration and development for the next generation.",
      isOpen: false,
      id: 3,
    },
    {
      question: "What makes Career Scoops different?",
      answere:
        "Career Scoops stands out by leveraging AI to deliver highly personalized career guidance, focusing on actionable insights and real-world exposure through micro-mentorship sessions. As part of our career discovery process, we enable young individuals to connect directly with professionals across various fields for customized advice, offering a comprehensive approach to career exploration and decision-making tailored to each person's unique profile. This blend of technology and personal interaction provides the necessary tools for informed career planning and development.",
      isOpen: false,
      id: 4,
    },
    {
      question: "Who are Community Partners (aka Mentors)?",
      answere:
        "Community Partners, also known as Mentors, are seasoned career professionals passionate about helping young individuals. Whether you have a background in law, healthcare or real estate, to name a few, we have a young individual in our network who can talk to you and learn from your insights and experiences.",
      isOpen: false,
      id: 5,
    },
    {
      question: "How do I become a Community Partner?",
      answere:
        "To become a Community Partner, also known as Mentor, simply sign up as a 'Community Partner' and complete your profile. This process allows you to join our network of professionals who are committed to guiding young individuals through their career journeys. Once registered, a representative will reach out to you regarding the next steps. Once the onboarding process is completed, you'll have the opportunity to connect with and mentor students, offering them insights and advice based on your professional experience.",
      isOpen: false,
      id: 6,
    },
    {
      question: "How much commitment is required?",
      answere:
        "We understand our community partners are often busy professionals with limited availability. Accordingly, we've designed a low-commitment, hassle-free involvement that requires just 30 minutes of your virtual time each month. This allows you to effectively assist young adults matched with careers in your area of expertise, making a significant impact with minimal time investment.",
      isOpen: false,
      id: 7,
    },
    {
      question: "Can I enroll my organization?",
      answere:
        "Yes, organizations can enroll in our Community Partnership Program, enhancing employee engagement by encouraging team participation in a meaningful cause. For details on enrolling your organization, please contact us at community@career-scoops.com.",
      isOpen: false,
      id: 8,
    },
  ],
};

export const partnersFeedback = [
  {
    isOpen: true,
    id: 1,
    img: girl,
    name: "Jan Edwards",
    position: "President of Paving the Way Foundation",
    feedBack: `I totally support the mission of Career Scoops because they offer
          a solution for those who are unclear about what they think they
          want or can do as a career.`,
  },
  {
    isOpen: false,
    id: 2,
    name: "Robert Stuart",
    img: man,
    position: "Commissioner, City of Orlando",
    feedBack: `Thank you for your support of this effective and worthwhile initiative that is truly making an impact in our community.`,
  },
  {
    isOpen: false,
    id: 3,
    img: woman,
    name: "Wendy Jarel",
    position: "Founder, Lead Strategist, Jarel Communications",
    feedBack: `Very few provide the steps and resources necessary to help students identify their dream career. This is a gap in their path to success, and I believe Career Scoops can help solve this issue.`,
  },
];

export const UserSettings = {
  student: [
    {
      department: "Recommendations",
      image: SesionStar,
      id: "Setting 2",
      isActive: false,
      activeImage: ActiveStar,
      route: "recommendations",
    },
    {
      department: "AI Insights Report",
      image: AiReport,
      id: "Setting 8",
      isActive: false,
      disableImage: LockReport,
    },
    {
      department: "Sessions",
      image: sessionIcon,
      id: "Setting 1",
      isActive: true,
      activeImage: ActivesessionIcon,
      route: "sessions",
    },
    {
      department: "Custom Request",
      image: sessionIcon,
      id: "Setting 7",
      isActive: false,
      activeImage: ActivesessionIcon,
      route: "customrequest",
    },
    {
      department: "Profile Info",
      image: ProfileIcon,
      id: "Setting 3",
      isActive: false,
      activeImage: ActiveProfileIcon,
      route: "profile-info",
    },
    {
      department: "My Plan",
      image: PlanIcon,
      id: "Setting 4",
      isActive: false,
      activeImage: ActivePlanIcon,
      route: "myPlan",
    },
    {
      department: "My Feedback",
      image: FeedBackIcon,
      id: "Setting 5",
      isActive: false,
      activeImage: ActiveFeedBacIcon,
      route: "MyFeedback",
    },
    {
      department: "Invite a Friend",
      image: AddIcon,
      id: "Setting 6",
      isActive: false,
      activeImage: ActiveAddIcon,
    },
  ],
  mentor: [
    {
      department: "Sessions",
      image: sessionIcon,
      id: "Setting 1",
      isActive: true,
      activeImage: ActivesessionIcon,
      route: "sessions",
    },
    {
      department: "Profile Info",
      image: ProfileIcon,
      id: "Setting 2",
      isActive: false,
      activeImage: ActiveProfileIcon,
      route: "profile-info",
    },

    {
      department: "My Feedback",
      image: FeedBackIcon,
      id: "Setting 3",
      isActive: false,
      activeImage: ActiveFeedBacIcon,
      route: "MyFeedback",
    },
    {
      department: "Invite a Friend",
      image: AddIcon,
      id: "Setting 4",
      isActive: false,
      activeImage: ActiveAddIcon,
    },
  ],
};
export const sessions = {
  active: [
    {
      date: new Date(2023, 5, 12, 12, 35, 26, 12),
      duaration: 90,
      topic: "Deep Space Scientist",
      mentor: "James Smith",
      id: Math.random(),
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend orci eu augue convallis, a semper ipsum blandit. Donec et pretium mauris. Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat.",
    },
    {
      date: new Date(2023, 7, 4, 13, 45, 36, 22),
      duaration: 226,
      topic: "Maths",
      mentor: "Adam Moris",
      id: Math.random(),
      text: "Vivamus maximus fringilla nisl, at bibendum felis consequat eu. Integer vel semper libero. In hac habitasse platea dictumst. Nam euismod viverra aliquet.",
    },
    {
      date: new Date(2023, 9, 6, 25, 52, 15, 13),
      duaration: 127,
      topic: "Biology",
      mentor: "Adam Moris",
      id: Math.random(),
      text: " Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat ligula bibendum. Vivamus maximus fringilla nisl, at bibendum felis consequat eu. Integer vel semper libero. In hac habitasse platea dictumst. Nam euismod viverra aliquet. Proin id erat sit amet ex pulvinar egestas vitae ac eros.",
    },
    {
      date: new Date(2023, 1, 5, 15, 55, 45, 33),
      duaration: 193,
      topic: "Literature",
      mentor: "Mark Williams",
      id: Math.random(),
      text: " Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat ligula bibendum. Vivamus maximus fringilla nisl, at bibendum felis consequat eu. Integer vel semper libero. In hac habitasse platea dictumst. Nam euismod viverra aliquet. Proin id erat sit amet ex pulvinar egestas vitae ac eros.",
    },
    {
      date: new Date(2023, 10, 5, 35, 15, 55, 10),
      duaration: 152,
      topic: "English",
      mentor: "Rachel Pudik",
      id: Math.random(),
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend orci eu augue convallis, a semper ipsum blandit. Donec et pretium mauris. Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat.",
    },
  ],
  history: [
    {
      date: new Date(2023, 5, 12, 12, 35, 26, 12),
      duaration: 90,
      topic: "Deep Space Scientist",
      mentor: "James Smith",
      id: Math.random(),
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend orci eu augue convallis, a semper ipsum blandit. Donec et pretium mauris. Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat.",
    },
    {
      date: new Date(2022, 7, 4, 13, 45, 36, 22),
      duaration: 226,
      topic: "Maths",
      mentor: "Adam Moris",
      id: Math.random(),
      text: "Vivamus maximus fringilla nisl, at bibendum felis consequat eu. Integer vel semper libero. In hac habitasse platea dictumst. Nam euismod viverra aliquet.",
    },
    {
      date: new Date(2021, 9, 6, 25, 52, 15, 13),
      duaration: 127,
      topic: "Biology",
      mentor: "Adam Moris",
      id: Math.random(),
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend orci eu augue convallis, a semper ipsum blandit. Donec et pretium mauris. Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat.",
    },
    {
      date: new Date(2020, 1, 5, 15, 55, 45, 33),
      duaration: 193,
      topic: "Literature",
      mentor: "Mark Williams",
      id: Math.random(),
      text: " Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat ligula bibendum. Vivamus maximus fringilla nisl, at bibendum felis consequat eu. Integer vel semper libero. In hac habitasse platea dictumst. Nam euismod viverra aliquet. Proin id erat sit amet ex pulvinar egestas vitae ac eros.",
    },
    {
      date: new Date(2017, 10, 5, 35, 15, 55, 10),
      duaration: 152,
      topic: "English",
      mentor: "Rachel Pudik",
      id: Math.random(),
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend orci eu augue convallis, a semper ipsum blandit. Donec et pretium mauris. Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat.",
    },
    {
      date: new Date(2023, 4, 3, 12, 35, 26, 12),
      duaration: 180,
      topic: "Deep Space Scientist",
      mentor: "James Smith",
      id: Math.random(),
      text: "Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat ligula bibendum. Vivamus maximus fringilla nisl, at bibendum felis consequat eu. Integer vel semper libero. In hac habitasse platea dictumst. Nam euismod viverra aliquet. Proin id erat sit amet ex pulvinar egestas vitae ac eros.",
    },
    {
      date: new Date(2022, 7, 4, 13, 45, 36, 22),
      duaration: 226,
      topic: "Maths",
      mentor: "Adam Moris",
      id: Math.random(),
      text: "Vivamus maximus fringilla nisl, at bibendum felis consequat eu. Integer vel semper libero. In hac habitasse platea dictumst. Nam euismod viverra aliquet. ",
    },
    {
      date: new Date(2015, 9, 6, 25, 52, 15, 13),
      duaration: 127,
      topic: "Biology",
      mentor: "Adam Moris",
      id: Math.random(),
      text: " Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat ligula bibendum. Vivamus maximus fringilla nisl, at bibendum felis consequat eu. Integer vel semper libero. In hac habitasse platea dictumst. Nam euismod viverra aliquet. Proin id erat sit amet ex pulvinar egestas vitae ac eros.",
    },
    {
      date: new Date(2012, 1, 5, 15, 55, 45, 33),
      duaration: 193,
      topic: "Literature",
      mentor: "Mark Williams",
      id: Math.random(),
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend orci eu augue convallis, a semper ipsum blandit. Donec et pretium mauris. Integer nec hendrerit velit. Sed rhoncus diam id ante ullamcorper, sit amet pretium nisl rhoncus. Nullam aliquam nulla non justo malesuada, vitae volutpat.",
    },
  ],
};
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const recommendations = [
  {
    Topic: "Deep Space Scientist",
    Mentor: "James Smith",
    starsCount: [1, 2, 3, 4, 5],
    id: "1",
    rate: 0,
  },
  {
    Topic: "Biology",
    Mentor: "Jane Air",
    starsCount: [1, 2, 3, 4, 5],
    id: "2",
    rate: 0,
  },
  {
    Topic: "Maths",
    Mentor: "Aaron Ramsey",
    starsCount: [1, 2, 3, 4, 5],
    id: "3",
    rate: 0,
  },
  {
    Topic: "Litreature",
    Mentor: "Deklan Ryce",
    starsCount: [1, 2, 3, 4, 5],
    id: "4",
    rate: 0,
  },
  {
    Topic: "English",
    Mentor: "Jack Grilish",
    starsCount: [1, 2, 3, 4, 5],
    id: "5",
    rate: 0,
  },
  {
    Topic: "Geography",
    Mentor: "Serjinyo Dest",
    starsCount: [1, 2, 3, 4, 5],
    id: "6",
    rate: 0,
  },
  {
    Topic: "Phisics",
    Mentor: "Viktor Hambarcumyan",
    starsCount: [1, 2, 3, 4, 5],
    id: "7",
    rate: 0,
  },
  {
    Topic: "Computer science",
    Mentor: "Bill Gates",
    starsCount: [1, 2, 3, 4, 5],
    id: "8",
    rate: 0,
  },
  {
    Topic: "Astronomy",
    Mentor: "John Stones",
    starsCount: [1, 2, 3, 4, 5],
    id: "9",
    rate: 0,
  },
];
