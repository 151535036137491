import { combineReducers } from "redux";
import { authReducer,mentorReducer, tagsReducer, scenariosReducer, pricingPlanReducer, profileReducer,FeedBackReducer,sessionReducer,industryReducer,recommendationReducer } from "./reducers";


const rootReducer = combineReducers({
  authReducer,
  mentorReducer,
  pricingPlanReducer,
  tagsReducer,
  scenariosReducer,
  profileReducer,
  FeedBackReducer,
  sessionReducer,
  industryReducer,
  recommendationReducer
});

export default rootReducer;
