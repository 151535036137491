export const INIT_SIGNIN = "INIT_SIGNIN";
export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

export const INIT_SIGNUP = "INIT_SIGNUP";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_SUCCESS_RESET = "SIGNUP_SUCCESS_RESET";

export const SIGN_OUT = "SIGN_OUT";

export const INIT_TAGS = "INIT_TAGS";
export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_ERROR = "GET_TAGS_ERROR";

export const INIT_MENTORS = "INIT_MENTORS";
export const GET_MENTORS = "GET_MENTORS";
export const MENTORS_SUCCESS = "MENTORS_SUCCESS";
export const MENTORS_FAIL = "MENTORS_FAIL";
export const INIT_INDUSTRY = "INIT_INDUSTRY";
export const GET_INDUSTRIES = "GET_INDUSTRIES";
export const GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";
export const GET_INDUSTRIES_FAIL = "GET_INDUSTRIES_FAIL";

export const INIT_SCENARIOS = "INIT_SCENARIOS";
export const GET_SCENARIOS = "GET_SCENARIOS";
export const GET_SCENARIOS_SUCCESS = "GET_SCENARIOS_SUCCESS";
export const GET_SCENARIOS_ERROR = "GET_SCENARIOS_ERROR";
export const INIT_SUBMIT_SCENARIOS = "INIT_SUBMIT_SCENARIOS";
export const SET_SCENARIOS = "SET_SCENARIOS";
export const SET_SCENARIOS_SUCCESS = "SET_SCENARIOS_SUCCESS";
export const SET_SCENARIOS_ERROR = "SET_SCENARIOS_ERROR";
export const RESET_SAVING_RESULT = "RESET_SAVING_RESULT";

export const INIT_RESULTS = "INIT_RESULTS";
export const GET_RESULTS = "GET_RESULTS";
export const GET_RESULTS_SUCCESS = "GET_RESULTS_SUCCESS";
export const GET_RESULTS_ERROR = "GET_RESULTS_ERROR";
export const INIT_SET_RESULTS = "INIT_SET_RESULTS";
export const SET_RESULTS = "SET_RESULTS";
export const SET_RESULTS_SUCCESS = "SET_RESULTS_SUCCESS";
export const SET_RESULTS_ERROR = "SET_RESULTS_ERROR";

export const INIT_GET_PRICING_PLAN = "INIT_GET_PRICING_PLAN";
export const GET_PLANS = "GET_PLANS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLAN_ERROR = "GET_PLAN_ERROR";
export const INIT_SET_PRICING_PLAN = "INIT_SET_PRICING_PLAN";
export const SET_PLAN = "SET_PLAN";
export const SET_PLAN_SUCCESS = "SET_PLAN_SUCCESS";
export const SET_PLAN_ERROR = "SET_PLAN_ERROR";

export const INIT_GET_PROFILE = "INIT_GET_PROFILE";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const INIT_SET_PROFILE = "INIT_SET_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_SUCCESS = "SET_PROFILE_SUCCESS";
export const SET_PROFILE_ERROR = "SET_PROFILE_ERROR";

export const INIT_GET_RECOMMENDATIONS = "INIT_GET_RECOMMENDATIONS";
export const GET_RECOMMENDATIONS = "GET_RECOMMENDATIONS";
export const GET_RECOMMENDATIONS_SUCCESS = "GET_RECOMMENDATIONS_SUCCESS";
export const GET_RECOMMENDATIONS_ERROR = "GET_RECOMMENDATIONS_ERROR";

export const INIT_GET_FEEDBACKS = "INIT_GET_FEEDBACKS"
export const GET_FEEDBACKS = "GET_FEEDBACKS"
export const GET_FEEDBACKS_SUCCESS = "GET_FEEDBACKS_SUCCESS"
export const GET_FEEDBACKS_ERROR = "GET_FEEDBACKS_ERROR"


export const INIT_GET_SESSIONS = "INIT_GET_SESSIONS"
export const GET_SESSIONS = "GET_SESSIONS"
export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS"
export const GET_SESSIONS_ERROR = "GET_SESSIONS_ERROR"
