import axiosClient from "./request";

export const routes = Object.freeze({
  auth: async (payload) => axiosClient.post(`auth/`, payload),
  signUp: async (payload) => axiosClient.post(`signup/`, payload),
  getTags: async (payload) => axiosClient.get(`tags/`, payload),
  getMentors: async (payload) => axiosClient.get("mentors/", payload),
  getScenarios: async (payload) => axiosClient.get("scenarios/", payload),
  setScenarios: async (payload) => axiosClient.post("scenarios/", payload),
  getResults: async (payload) => axiosClient.get("result/", payload),
  setResults: async (path, payload) =>
    axiosClient.patch(`result/${path}/`, payload),
  getPricingPlan: async (payload) => axiosClient.get("pricing_plan/", payload),
  setPricingPlan: async (path) =>
    axiosClient.patch(`profile/`, { pricing_plan: path }),
  getProfile: async (payload) => axiosClient.get("profile/", payload),
  setProfile: async (payload) => axiosClient.patch(`profile/`, payload),
  getRecommendations: async (payload) => axiosClient.get(`recommendations/`, payload),
  getFeedBacks: async (payload) => axiosClient.get("mentor_feedbacks/",payload), 
  getSessions: async (payload) => axiosClient.get("mentor_sessions/",payload), 
  getIndustries: async (payload) => axiosClient.get("industries/",payload), 
});
